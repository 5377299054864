import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "../assets/styles.scss";
import Main from "../layout/layout";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Row, Col, Card } from "antd";
import Masonry from "react-masonry-css";
import ImageViewer from "../layout/imageViewer";
import { Helmet } from "react-helmet";
import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from "gatsby-plugin-react-i18next";
import SEO from "../layout/seo";

// markup
const Photos = ({ location, data }) => {
  const entry1 = data.allContentfulPhotoSeries.edges;
  const entry2 = data.allContentfulSinglePhotos.edges;

  const { t } = useTranslation();

  useEffect(() => {
    let imgs = [];

    for (let entry of entry1) {
      for (let img of entry.node.photos) {
        imgs.push({ image: img });
      }
    }

    for (let entry of entry2) {
      for (let img of entry.node.photos) {
        imgs.push({ image: img });
      }
    }

    const shuffledArray = imgs.sort((a, b) => 0.5 - Math.random());

    setFilteredImages(shuffledArray);
    console.log(shuffledArray)
  }, [entry1, entry2]);

  const [filteredImages, setFilteredImages] = useState([]);
  const [viewerVisible, setViewerVisible] = useState(false);
  const [photoVisible, setPhotoVisible] = useState(false);
  const [indexPhotoVisble, setIndexPhotoVisible] = useState(0);

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  const toggleImageViewer = () => {
    if (viewerVisible === false) {
      document.body.classList.add("viewer-visibile");
    } else {
      document.body.classList.remove("viewer-visibile");
    }
    setViewerVisible(!viewerVisible);
  };

  const previous = () => {
    let key = indexPhotoVisble;
    if (key > 0) {
      key = indexPhotoVisble - 1;
    }
    setIndexPhotoVisible(key);
    setPhotoVisible(filteredImages[key].image);
  };

  const next = () => {
    let key = indexPhotoVisble;
    if (key < filteredImages.length - 1) {
      key = indexPhotoVisble + 1;
    }
    setIndexPhotoVisible(key);
    setPhotoVisible(filteredImages[key].image);
  };

  const openPhoto = (photo, key) => {
    setPhotoVisible(photo);
    setIndexPhotoVisible(key);
    toggleImageViewer();
  };


  return (
    <main>
      <SEO title="Photos" description={t("Description about")}></SEO>


      <Main location={location}>
        <div className="main-container">
          {viewerVisible ? (
            <ImageViewer
              close={toggleImageViewer}
              previous={indexPhotoVisble === 0 ? false : previous}
              next={
                indexPhotoVisble === filteredImages.length - 1 ? false : next
              }
              image={photoVisible}
            ></ImageViewer>
          ) : (
            <></>
          )}

          <div style={{ marginBottom: "2rem" }} className="title-page">
            Photos
            <div className="hr"></div>
          </div>


          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {filteredImages.map((img, key) => {
              let image = getImage(img.image);

              return (
                <div onClick={() => openPhoto(img.image, key)}>
                  <GatsbyImage
                    objectFit="cover"
                    style={{ cursor: "pointer" }}
                    image={image}
                    alt={""}
                  />
                </div>
              );
            })}
          </Masonry>
        </div>
      </Main>
    </main>
  );
};
export const data = graphql`
  query langPhotos($language: String!) {
    allContentfulSinglePhotos {
      edges {
        node {
          id
          name
          photos {
            title
            createdAt
            gatsbyImageData(
              width: 1500
              placeholder: DOMINANT_COLOR
              formats: [AUTO, AVIF, WEBP]
            )
          }
        }
      }
    }
    allContentfulPhotoSeries {
      edges {
        node {
          id
          name
          photos {
            title
            createdAt
            gatsbyImageData(
              width: 1500
              placeholder: DOMINANT_COLOR
              formats: [AUTO, AVIF, WEBP]
            )
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Photos;
